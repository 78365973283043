<template>
  <el-dialog
    title="分配菜单"
    :visible.sync="dialogData.isOpen"
    width="550px"
    :before-close="closeHandler"
    center
  >
    <el-card>
      <el-tree
        :data="menuList"
        show-checkbox
        default-expand-all
        node-key="id"
        ref="menuTree"
        check-strictly
        :default-expanded-keys="[1, 2, 3, 4]"
        :default-checked-keys="roleMenuList"
        :props="defaultProps"
      >
      </el-tree>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeHandler">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { listMenuIdsByRoleId, listTree, setRoleMenu } from '@/api/login'

export default {
  props: {
    dialogData: {
      id: String,
      isOpen: Boolean
    }
  },
  data () {
    return {
      menuList: [],
      roleMenuList: [],
      defaultProps: {
        children: 'children',
        label: 'menuName'
      }
    }
  },
  watch: {
    'dialogData.isOpen' () {
      if (this.dialogData.isOpen) {
        this.listMenuIdsByRoleId()
        listMenuIdsByRoleId(this.dialogData.id).then(res => {
          this.roleMenuList = []
          this.roleMenuList = res.data
          this.$refs.menuTree.setCheckedKeys(this.roleMenuList)
        })
      }
    }
  },
  methods: {
    listMenuIdsByRoleId () {
      listTree().then(res => {
        this.menuList = res.data
      })
    },
    save () {
      this.$confirm('是否修改该角色的权限？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const checkData = [...this.$refs.menuTree.getCheckedKeys()]
          const roleMenu = {
            roleId: this.dialogData.id,
            menuIds: checkData
          }
          setRoleMenu(roleMenu).then(() => {
            this.$message({
              message: '修改角色权限成功',
              type: 'success'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消修改该角色的权限'
          })
        })
    },
    closeHandler () {
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>
