<template>
  <div class="page-coantainer">
    <el-dialog
      title="添加角色"
      :visible.sync="addData.isOpen"
      width="40%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
          <el-form-item label="角色名" prop="roleName">
            <el-input v-model.trim="formData.roleName"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addRole } from '@/api/login'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        roleName: ''
      },
      sex: ['男', '女'],
      rules: {
        roleName: [
          { required: true, message: '请输入角色名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    /* 添加角色 */
    addRole () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addRole(this.formData).then(res => {
            this.$emit('closeDialog', { isOpen: false, type: 'success' })
            this.$refs.form.resetFields()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
          })
        }
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;
    }
  }
}
</style>
